const LooxReviews = () => {
  return (
    <div>
      <div className="rev-vid-lightbox-css w-embed"></div>
      <div id="rev-wrapper" className="reviews-sec is-main">
        <h2 className="centered-heading with-padding">
          Real People, Real Reviews
        </h2>
        <div className="rev-con newloox w-container">
          <div className="loox-reviews-box">
            <div className="w-embed">
              <div
                id="looxReviews"
                data-loox-aggregate=""
                data-write-btn="hidden"
                data-header="false"
                data-product-thumbnails="false"
                data-variant="visible"
                data-product-id="1"
                data-limit-mobile="6"
                className="loox-reviews-default"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LooxReviews;
