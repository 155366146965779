import { Helmet } from "react-helmet";
import { useState } from "react";
import axios from "axios";
import { Oval } from "react-loader-spinner";

const AmazonSupport = () => {
  const [loader, setLoader] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    country: "",
    email: "",
    amazonOrderNumber: "",
    message: "",
  });

  const resetForm = () => {
    setForm({
      firstName: "",
      lastName: "",
      country: "",
      email: "",
      amazonOrderNumber: "",
      message: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const sendEmail = async () => {
    if (form.firstName === "") {
      alert("First Name is required");
      return;
    }

    if (form.lastName === "") {
      alert("Last Name is required");
      return;
    }

    if (form.country === "") {
      alert("Country is required");
      return;
    }

    if (form.amazonOrderNumber === "") {
      alert("Amazon Order Number is required");
      return;
    }

    if (form.email === "") {
      alert("Email is required");
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(form.email)) {
      alert("Email is not valid");
      return;
    }

    setLoader(true);

    setTimeout(() => {
      setLoader(false);
      setEmailSent(true);
    }, 3000);

    setTimeout(() => {
      setEmailSent(false);
    }, 5000);

    const apiKey =
      "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";
    const endpoint = "https://api.brevo.com/v3/smtp/email";

    console.log("form", form);

    const emailData = {
      sender: { name: "Felo", email: form.email }, // Use your own domain
      to: [{ email: "help@getfelo.com", name: "Felo Help" }], // Where you want to receive the email
      replyTo: {
        email: form.email,
        name: `${form.firstName} ${form.lastName}`,
      }, // This is the user's email, so you can reply to it
      subject: `Amazon Order Number(${form.amazonOrderNumber}) - Country(${form.country})`,
      htmlContent: `<p>${form.message}</p>`,
    };

    try {
      const response = await axios.post(endpoint, emailData, {
        headers: {
          "api-key": apiKey,
          "Content-Type": "application/json",
        },
      });
      resetForm();
      console.log("Email sent successfully:", response.data);
    } catch (error) {
      console.error(
        "Error sending email:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div>
      <div class="form-wrapper-amazon">
        <div class="w-layout-blockcontainer amazon_form-container w-container">
          <div class="inner-amazon-wrapper">
            <div class="main-contact_block">
              <h2 class="form-title-cf1">
                Fill in your information
                <br />
              </h2>
              <div class="amazon_form-block w-form">
                <form
                  id="wf-form-Felo-Amazon-Form"
                  name="wf-form-Felo-Amazon-Form"
                  data-name="Felo Amazon Form"
                  method="get"
                  class="form-cf1"
                  data-wf-page-id="66c4f77b45a8a938c00d1fa4"
                  data-wf-element-id="a3e0bdde-f20e-904b-7a95-d796745dbde5"
                >
                  <div class="field_name-wrapper">
                    <label for="" class="name-label">
                      Name<span class="is-required">*</span>
                    </label>
                    <div class="name-inner-div">
                      <div class="field_name-inner">
                        <input
                          class="text-field-cf1 w-input"
                          maxlength="256"
                          data-name="First Name"
                          placeholder="First Name"
                          type="text"
                          id="First-Name"
                          name="firstName"
                          value={form.firstName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div class="field_name-inner last">
                        <input
                          class="text-field-cf1 w-input"
                          maxlength="256"
                          data-name="Last Name"
                          placeholder="Last Name"
                          type="text"
                          name="lastName"
                          value={form.lastName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <label for="Email" class="field-label-cf1">
                    Email<span class="is-required">*</span>
                  </label>
                  <input
                    class="text-field-cf1 w-input"
                    maxlength="256"
                    data-name="Email"
                    placeholder="Enter your email address"
                    type="email"
                    name="email"
                    value={form.email}
                    onChange={handleInputChange}
                  />
                  <label for="Country" class="field-label-cf1">
                    Country<span class="is-required">*</span>
                  </label>
                  <select
                    id="Country"
                    data-name="Country"
                    required=""
                    class="select-field-amazon w-select"
                    name="country"
                    value={form.country}
                    onChange={handleInputChange}
                  >
                    <option value="United States">United States</option>
                    <option value="Canada">Canada</option>
                    <option value="Mexico">Mexico</option>
                  </select>
                  <label for="Amazon-Order-Number" class="field-label-cf1">
                    Amazon Order Number<span class="is-required">*</span>
                  </label>
                  <input
                    class="text-field-cf1 w-input"
                    maxlength="256"
                    data-name="Amazon Order Number"
                    placeholder="xxx-xxxxxxx-xxxxxxx"
                    type="text"
                    name="amazonOrderNumber"
                    value={form.amazonOrderNumber}
                    onChange={handleInputChange}
                  />
                  <label for="Message" class="field-label-cf1">
                    Questions or Suggestions
                  </label>
                  <textarea
                    id="Message"
                    maxlength="5000"
                    placeholder=""
                    required=""
                    class="text-field-cf1 big w-input"
                    name="message"
                    value={form.message}
                    onChange={handleInputChange}
                  ></textarea>
                  <button
                    type="button"
                    data-wait="Please wait..."
                    class="submit-button-cf1 w-button"
                    value="Submit"
                    onClick={sendEmail}
                  >
                    {!loader && !emailSent ? (
                      "Send"
                    ) : emailSent ? (
                      <span>
                        Email Sent <i class="fa-solid fa-check"></i>
                      </span>
                    ) : (
                      <span>
                        <Oval
                          height="25"
                          width="25"
                          color="#ffffff"
                          secondaryColor="ffffff"
                          ariaLabel="oval-loading"
                          wrapperClass="loader-wrapper"
                        />
                      </span>
                    )}
                  </button>
                </form>
                <div class="success-message-cf1 w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div class="error-message-cf1 w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                    <br />
                    Please refresh and try again.
                  </div>
                </div>
              </div>
            </div>
            <div class="other_block">
              <img
                src="images/mid-new.jpg"
                loading="lazy"
                sizes="(max-width: 479px) 90vw, (max-width: 767px) 43vw, (max-width: 1279px) 45vw, 450px"
                srcset="
                images/mid-new-p-500.jpg 500w,
                images/mid-new-p-800.jpg 800w,
                images/mid-new.jpg       963w
              "
                alt=""
                class="cs-block-img"
              />
              <h2 class="cs-title-headline">
                Customer Support
                <br />
              </h2>
              <div class="cs-divider"></div>
              <div class="cs-text-below">
                If you have any questions about our products or need help with
                your order, please fill out the form below. We also welcome any
                suggestions you may have.
              </div>
              <div class="cs-text-below">
                To ensure we can assist you more efficiently, please use the
                email address associated with your Amazon account.
              </div>
              <div class="cs-text-below">
                Please also provide your Amazon Order Number from your order
                history or in your email confirmation.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmazonSupport;
