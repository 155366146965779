import { useState } from "react";
import { useRef } from "react";
import Slider from "react-slick";
const SectionVideoSlider = () => {
  const iframeRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "80px",
        },
      },
    ],
  };

  const videoLinks = [
    {
      img: "img/sleep_1.webp",
      src: "https://fast.wistia.net/embed/iframe/g5ha9fwav2?pageUrl=https%3A%2F%2Fwww.getfelo.com%2F",
    },
    {
      img: "img/sleep_2.webp",
      src: "https://fast.wistia.net/embed/iframe/fv3ricsw99?pageUrl=https%3A%2F%2Fwww.getfelo.com%2F",
    },
    {
      img: "img/sleep_3.webp",
      src: "https://fast.wistia.net/embed/iframe/eubo09aaqs?pageUrl=https%3A%2F%2Fwww.getfelo.com%2F",
    },
    {
      img: "img/sleep_4.webp",
      src: "https://fast.wistia.net/embed/iframe/2mp8fvr42n?pageUrl=https%3A%2F%2Fwww.getfelo.com%2F",
    },
    {
      img: "img/sleep_5.webp",
      src: "https://fast.wistia.net/embed/iframe/32b5ladeuj?pageUrl=https%3A%2F%2Fwww.getfelo.com%2F",
    },
    {
      img: "img/sleep_6.webp",
      src: "https://fast.wistia.net/embed/iframe/lvmagr9fhe?pageUrl=https%3A%2F%2Fwww.getfelo.com%2F",
    },
    {
      img: "img/sleep_7.webp",
      src: "https://fast.wistia.net/embed/iframe/wfd26w9qa2?pageUrl=https%3A%2F%2Fwww.getfelo.com%2F",
    },
  ];

  const [selectedLink, setSelectedLink] = useState({
    img: "img/sleep_2.webp",
    src: "https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Ffast.wistia.net%2Fembed%2Fiframe%2F3gispq145y&display_name=Wistia%2C+Inc.&url=https%3A%2F%2Fgetnuzzle.wistia.com%2Fmedias%2F3gispq145y&image=https%3A%2F%2Fembed-ssl.wistia.com%2Fdeliveries%2F2321ec33b38fdd5a29a5a7eaeaac70d2c6c29759.jpg%3Fimage_crop_resized%3D960x720&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=wistia",
  });

  const openVideo = (link) => {
    setOpenModal(true);
    setSelectedLink(link);
  };

  const stopVideo = () => {
    setOpenModal(false);
    if (iframeRef.current) {
      const iframeSrc = iframeRef.current.src;
      iframeRef.current.src = iframeSrc;
    }
  };

  return (
    <div className="section_video-slider">
      <div className="hdr-div">
        <div className="w-layout-blockcontainer hdr-con w-container">
          <div className="features_heading-block">
            <div className="video-stars-block">
              <div className="video-stars-inner">
                <div>
                  <span className="star-blue-mid"></span>
                </div>
              </div>
              <div>
                <div className="vid-rev-text">4.6 out of 5 stars</div>
              </div>
            </div>
            <h3 className="features_heading-text is-centered is-p">
              Join Thousands Who Have Transformed <br />
              Their Sleep With Felo
            </h3>
          </div>
        </div>
      </div>
      <div className="SleepSwiper">
        <Slider {...settings}>
          {videoLinks.map((link, index) => (
            <div
              className="swiper-slide"
              key={index}
              onClick={() => openVideo(link)}
            >
              <img src={link.img} alt="Sleep" />
              <svg width="40" viewBox="0 0 67 68">
                <path d="M47.0352 33.9592L26.7102 45.6938L26.7102 22.2245L47.0352 33.9592Z"></path>
              </svg>
            </div>
          ))}
        </Slider>
      </div>
      {openModal && (
        <div className="modal-overlay FeloModel">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={stopVideo}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <iframe
              ref={iframeRef}
              src={selectedLink.src}
              scrolling="no"
              allowfullscreen=""
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionVideoSlider;
