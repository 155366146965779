import mainStore from "../../store/main";

const BuyNow = () => {
  const { setDrawer } = mainStore();

  return (
    <div class="stick-nav-mobile">
      <a
        href="#"
        class="mobile-button_sticky w-inline-block"
        onClick={() => setDrawer(true)}
      >
        <div class="mb_sticky-text">BUY NOW!</div>
      </a>
    </div>
  );
};

export default BuyNow;
