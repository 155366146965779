import { create } from "zustand";
import { persist } from "zustand/middleware";

const mainStore = create(
  persist(
    (set, get) => ({
      openDrawer: false,
      quantity: 2,
      isUpgrade: false,
      timer: "06:35:34",
      timerInterval: null,
      setDrawer: (state) => {
        set({ openDrawer: state });
      },
      setQuantity: (quantity) => {
        set({ quantity: quantity });
      },
      setIsUpgrade: (isUpgrade) => {
        set({ isUpgrade: isUpgrade });
      },
      startTimer: (state) => {
        let hours = 6;
        let minutes = 35;
        let seconds = 34;

        if (get().timerInterval) {
          clearInterval(get().timerInterval);
        }
        const updateTimer = () => {
          if (hours === 0 && minutes === 0 && seconds === 0) {
            clearInterval(get().timerInterval);
            return;
          }

          if (seconds === 0) {
            if (minutes === 0) {
              hours -= 1;
              minutes = 59;
            } else {
              minutes -= 1;
            }
            seconds = 59;
          } else {
            seconds -= 1;
          }

          // Format the timer string as HH:MM:SS
          const formattedTimer = [
            hours.toString().padStart(2, "0"),
            minutes.toString().padStart(2, "0"),
            seconds.toString().padStart(2, "0"),
          ].join(":");

          set({ timer: formattedTimer });
        };

        const interval = setInterval(updateTimer, 1000); // Update every second
        set({ timerInterval: interval });
      },

      time: 0, // Time in milliseconds
      isActive: false,
      startTodayRemaningTime: null,
      timerId: null,

      startTodayRemaningTimer: (initialTime) => {
        set((state) => {
          if (state.timerId) clearInterval(state.timerId); // Clear any existing timer

          const now = Date.now();
          const endTime = now + initialTime; // Set the end time
          const timerId = setInterval(() => {
            const remainingTime = endTime - Date.now();
            if (remainingTime <= 0) {
              clearInterval(timerId);
              set({ time: 0, isActive: false, timerId: null });
            } else {
              set({ time: remainingTime, isActive: true, timerId });
            }
          }, 1000); // Update every second

          return { startTime: now, isActive: true, timerId, time: initialTime };
        });
      },
    }),
    {
      name: "main-store", // Name of the storage (must be unique)
      getStorage: () => localStorage, // (optional) default is 'localStorage'
    }
  )
);

export default mainStore;
