import { useEffect, useState } from "react";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import moment from "moment";
import mainStore from "../../store/main";
import mixpanel from "mixpanel-browser";
mixpanel.init("6a65c67689e1f7382d034cc2077dde08");

const Drawer = () => {
  const {
    openDrawer,
    setDrawer,
    quantity,
    setQuantity,
    isUpgrade,
    setIsUpgrade,
    time,
  } = mainStore();
  const { timer } = mainStore();

  const [loading, setLoading] = useState(false);
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const [continueToCheckoutLoading, setContinueToCheckoutLoading] =
    useState(false);
  const [upgradeModalLoader, setUpgradeModalLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [dealActualPrice, setDealActualPrice] = useState(79);
  const [dealPrice, setDealPrice] = useState(29.95);
  const [showUpgradeDeal, setShowUpgradeDeal] = useState("");

  let currentDate = moment();

  // Calculate the next business day
  while (currentDate.day() === 6 || currentDate.day() === 0) {
    currentDate.add(1, "day");
  }

  let oneWeekAhead = currentDate.add(1, "day");

  // Adjust again if the new date falls on a weekend
  while (oneWeekAhead.day() === 6 || oneWeekAhead.day() === 0) {
    oneWeekAhead.add(1, "day");
  }
  let shipsByDate = oneWeekAhead.format("ddd, MMM Do");
  let freeGiftsDate = moment().format("MMM Do");

  const queenSizeVariant = "49345725006139";
  const kingSizeVariant = "49346363785531";
  const freePillowVariantQueen = "49345760002363";
  const freePillowVariantKing = "49371815510331";
  const mysteryGiftVariant = "49349250482491";

  useEffect(() => {
    resetLoadings();
  }, []);

  useEffect(() => {
    if (openDrawer) {
      document.body.classList.add("overflow_body");
    } else {
      document.body.classList.remove("overflow_body");
    }
  }, [openDrawer]);

  useEffect(() => {
    if (!isUpgrade) {
      setDealActualPrice(79);
      setDealPrice(29.95);
    } else {
      setDealActualPrice(99);
      setDealPrice("39.90");
    }
  }, [isUpgrade]);

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

    return formattedTime;
  };

  const onUpgrade = () => {
    setIsUpgrade(!isUpgrade);
  };

  const onHandleChange = (counter) => {
    if (
      (counter === "decrease" && quantity <= 1) ||
      (counter === "increase" && quantity >= 3)
    ) {
      return;
    }

    const count = counter === "increase" ? quantity + 1 : quantity - 1;
    setQuantity(count);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const proceedToCheckout = () => {
    if (quantity === 1 || !isUpgrade) {
      if (quantity >= 2) {
        setShowUpgradeDeal("upgradeToKing");
      } else {
        setShowUpgradeDeal("specialDeal");
      }
      setOpenModal(true);
    } else {
      checkout(true);
    }
  };

  const proceedFromUpgradeModal = (upgrade, param) => {
    if (!upgrade) {
      checkout(false, param);
    } else if (param === "acceptUpgradeOffer") {
      setIsUpgrade(true);
      checkout(true, param);
    } else if (param === "acceptSpecialOffer") {
      setQuantity(2);
      checkout(isUpgrade, param, 2);
    } else if (param === "rejectSpecialOffer") {
      checkout(isUpgrade, param);
    }
  };

  const checkout = async (upgrade, param, upgradedQuantity) => {
    if (param === "acceptUpgradeOffer") {
      mixpanel.track("Pop Deal Accepted", {
        deal_name: "king-upgrade",
      });
    } else if (param === "acceptSpecialOffer") {
      mixpanel.track("Pop Deal Accepted", {
        deal_name: "extra-pillow",
      });
    }

    if (upgrade) {
      mixpanel.track("Pop Deal Accepted", {
        deal_name: "Upgrade to King",
      });
    }

    const lineItems = [
      {
        variantId: `gid://shopify/ProductVariant/${
          upgrade ? kingSizeVariant : queenSizeVariant
        }`,
        quantity: upgradedQuantity ? upgradedQuantity : quantity,
      },
    ];

    if (quantity >= 3) {
      lineItems.push({
        variantId: `gid://shopify/ProductVariant/${
          upgrade ? freePillowVariantKing : freePillowVariantQueen
        }`,
        quantity: 1,
      });
      lineItems.push({
        variantId: `gid://shopify/ProductVariant/${mysteryGiftVariant}`,
        quantity: 1,
      });
    }

    const createCheckoutQuery = `
          mutation {
            checkoutCreate(input: {
              lineItems: [
                ${lineItems
                  .map(
                    (item) => `
                {
                  variantId: "${item.variantId}",
                  quantity: ${item.quantity}
                }`
                  )
                  .join(",")}
              ]
            }) {
              checkout {
                id
                webUrl
              }
              userErrors {
                field
                message
              }
            }
          }
        `;

    if (!param) {
      setLoading(true);
    } else if (
      param === "acceptUpgradeOffer" ||
      param === "acceptSpecialOffer"
    ) {
      setUpgradeLoading(true);
    } else if (
      param === "rejectUpgradeOffer" ||
      param === "rejectSpecialOffer"
    ) {
      setContinueToCheckoutLoading(true);
    } else if (param === "modalClose") {
      setUpgradeModalLoader(true);
    }

    try {
      const response = await axios.post(
        "https://secure.getfelo.com/api/2023-07/graphql.json",
        { query: createCheckoutQuery },
        {
          headers: {
            "X-Shopify-Storefront-Access-Token":
              "16cdda8ce0fae6ef8f78fe27d99f64e2",
            "Content-Type": "application/json",
          },
        }
      );

      const checkout = response.data.data.checkoutCreate.checkout;

      if (checkout) {
        // Apply the first discount code
        const applyFirstDiscountCodeQuery = `
              mutation {
                checkoutDiscountCodeApplyV2(
                  discountCode: "${upgrade ? "62% OFF" : "62% OFF!"}",
                  checkoutId: "${checkout.id}"
                ) {
                  checkout {
                    id
                    webUrl
                  }
                  userErrors {
                    field
                    message
                  }
                }
              }
            `;

        const firstDiscountResponse = await axios.post(
          "https://secure.getfelo.com/api/2023-07/graphql.json",
          { query: applyFirstDiscountCodeQuery },
          {
            headers: {
              "X-Shopify-Storefront-Access-Token":
                "16cdda8ce0fae6ef8f78fe27d99f64e2",
              "Content-Type": "application/json",
            },
          }
        );

        const firstDiscountCheckout =
          firstDiscountResponse.data.data.checkoutDiscountCodeApplyV2.checkout;

        if (firstDiscountCheckout) {
          // Apply the second discount code
          const applySecondDiscountCodeQuery = `
                mutation {
                  checkoutDiscountCodeApplyV2(
                    discountCode: "GIFT",
                    checkoutId: "${firstDiscountCheckout.id}"
                  ) {
                    checkout {
                      id
                      webUrl
                    }
                    userErrors {
                      field
                      message
                    }
                  }
                }
              `;

          const secondDiscountResponse = await axios.post(
            "https://secure.getfelo.com/api/2023-07/graphql.json",
            { query: applySecondDiscountCodeQuery },
            {
              headers: {
                "X-Shopify-Storefront-Access-Token":
                  "16cdda8ce0fae6ef8f78fe27d99f64e2",
                "Content-Type": "application/json",
              },
            }
          );

          const secondDiscountCheckout =
            secondDiscountResponse.data.data.checkoutDiscountCodeApplyV2
              .checkout;

          if (secondDiscountCheckout) {
            setTimeout(() => {
              resetLoadings();
            }, 1000);
            window.location.href = secondDiscountCheckout.webUrl;
          } else {
            resetLoadings();
            console.error(
              "User errors:",
              secondDiscountResponse.data.data.checkoutDiscountCodeApplyV2
                .userErrors
            );
          }
        } else {
          resetLoadings();
          console.error(
            "User errors:",
            firstDiscountResponse.data.data.checkoutDiscountCodeApplyV2
              .userErrors
          );
        }
      } else {
        resetLoadings();
        console.error(
          "User errors:",
          response.data.data.checkoutCreate.userErrors
        );
      }
    } catch (error) {
      resetLoadings();
      console.error("Error creating checkout:", error);
    }
  };

  const resetLoadings = () => {
    onCloseModal();
    setLoading(false);
    setUpgradeLoading(false);
    setContinueToCheckoutLoading(false);
    setUpgradeModalLoader(false);
  };

  return (
    <div>
      <div
        id="cart-overlay"
        className={`cart-overlay ${openDrawer ? "cart-overlay-bg " : ""}`}
      ></div>
      <article className="drawer-holder_wrapper">
        <div className="drawer_inside"></div>
        <div
          data-drawer="drawer"
          class={`drawer_inside-box ${openDrawer ? "is-active" : ""}`}
        >
          <div id="checkout-maininfo" className="drawer_inside-inner">
            <div id="checkout-info" className="drawer_inside-content">
              <div className="new-drawer_head-block">
                <div class="cart-head_back-text">Your Cart</div>
                <a
                  id="close-drawer"
                  href="#"
                  className="cart-head_back-block w-inline-block"
                  onClick={() => setDrawer(false)}
                >
                  <div class="cart-back-icon w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewbox="0 0 24 24"
                      fill="none"
                      stroke="#ffffff"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </div>
                </a>
              </div>
              <div class="offer_bar-wrapper">
                <div class="offer_bar-text">
                  🎩 VIP ACCESS - BLACK FRIDAY SALE 🛍️
                </div>
              </div>
              <div class="cart-head_inner-block">
                <div class="cart-head_title">UNLOCK FREE GIFTS</div>
                <div id="cart_heading_text" class="cart-head_heading">
                  {quantity === 1
                    ? "Add a 2nd Pillow, Get FREE Shipping"
                    : quantity === 2
                    ? "Add a 3rd Pillow, Get The 4th FREE"
                    : "Congrats, All Gifts Are Unlocked!"}
                </div>
              </div>
              <div className="spacer-xsmall"></div>
              <div className="new-drawer_gift-block">
                <div data-gift="container" className="gift-blocks">
                  <div data-gift="1" className="gift_card is-active">
                    <img
                      id="gift1image"
                      loading="eager"
                      alt=""
                      src="images/Small-02.svg"
                      className="gift_image"
                    />
                    <div data-bundle-gift="1" className="gift-overlay"></div>
                    <div className="gift-locker">
                      <div className="gift-lockings w-embed">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 18.6 18.6"
                          style={{ enableBackground: "new 0 0 18.6 18.6" }}
                          width="100%"
                          height="100%"
                        >
                          <path
                            fill="CurrentColor"
                            d="M14.6,11c0,0.8,0,1.6,0,2.4c0,0.8-0.3,1.4-0.8,2c-0.8,0.9-1.9,1.4-3.1,1.6c-1.1,0.2-2.3,0.2-3.4-0.1
    c-0.9-0.3-1.8-0.7-2.5-1.4c-0.6-0.6-1-1.4-0.9-2.3c0-1.5,0-3,0-4.4c0-0.9,0.4-1.5,1.2-1.9c0.1,0,0.1-0.1,0.1-0.2c0-1,0.2-2,0.7-2.9
    c0.5-0.9,1.1-1.6,2-2c1.2-0.6,2.4-0.4,3.5,0.5c0.8,0.6,1.2,1.4,1.6,2.3c0.3,0.7,0.4,1.4,0.4,2.2c0,0.1,0,0.1,0.1,0.2
    c0.8,0.4,1.2,1,1.2,1.8C14.6,9.5,14.6,10.3,14.6,11C14.6,11,14.6,11,14.6,11z M9.3,6.7C9.3,6.7,9.3,6.7,9.3,6.7c0.7,0,1.4,0,2,0
    c0.4,0,0.4,0,0.3-0.4c-0.1-0.8-0.3-1.6-0.8-2.2c-0.5-0.7-1.2-0.9-2-0.8c-0.6,0.1-1,0.5-1.3,1C7.1,5,6.9,5.8,6.9,6.6
    c0,0.1,0,0.2,0.1,0.2C7.8,6.7,8.5,6.7,9.3,6.7z M9.3,13.8C9.3,13.8,9.3,13.8,9.3,13.8c0.2,0,0.4,0,0.7,0c0.1,0,0.2,0,0.2-0.2
    c0-0.3,0-0.7,0-1c0-0.1,0-0.2,0.1-0.3c0.9-0.7,0.7-2.2-0.4-2.6c-0.7-0.3-1.5,0-1.9,0.6c-0.4,0.6-0.2,1.5,0.4,1.9
    c0.1,0.1,0.1,0.1,0.1,0.2c0,0.4,0,0.7,0,1.1c0,0.1,0,0.2,0.1,0.1C8.8,13.8,9,13.8,9.3,13.8z"
                          />
                        </svg>
                      </div>
                      <div data-gift-unlock="1" className="gift-unlockers">
                        1 Pillow
                      </div>
                    </div>
                    <div
                      data-bundle-gift="2"
                      className="gift-checkmark is-active w-embed"
                    >
                      <svg
                        width="420"
                        height="420"
                        viewbox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                    <div className="gift-price-badge">
                      <div className="text-block-228">62% OFF</div>
                    </div>
                  </div>
                  <div
                    data-gift="2"
                    class={`gift_card ${quantity >= 2 ? "is-active" : ""}`}
                  >
                    <img
                      id="gift1image"
                      loading="eager"
                      alt=""
                      src="images/Small-04.svg"
                      className="gift_image"
                    />
                    <div data-bundle-gift="2" className="gift-overlay"></div>
                    <div className="gift-locker">
                      <div className="gift-lockings w-embed">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          viewBox="0 0 18.6 18.6"
                          style={{ enableBackground: "new 0 0 18.6 18.6" }}
                          width="100%"
                          height="100%"
                        >
                          <path
                            fill="currentColor"
                            d="M14.6,11c0,0.8,0,1.6,0,2.4c0,0.8-0.3,1.4-0.8,2c-0.8,0.9-1.9,1.4-3.1,1.6c-1.1,0.2-2.3,0.2-3.4-0.1
    c-0.9-0.3-1.8-0.7-2.5-1.4c-0.6-0.6-1-1.4-0.9-2.3c0-1.5,0-3,0-4.4c0-0.9,0.4-1.5,1.2-1.9c0.1,0,0.1-0.1,0.1-0.2c0-1,0.2-2,0.7-2.9
    c0.5-0.9,1.1-1.6,2-2c1.2-0.6,2.4-0.4,3.5,0.5c0.8,0.6,1.2,1.4,1.6,2.3c0.3,0.7,0.4,1.4,0.4,2.2c0,0.1,0,0.1,0.1,0.2
    c0.8,0.4,1.2,1,1.2,1.8C14.6,9.5,14.6,10.3,14.6,11C14.6,11,14.6,11,14.6,11z M9.3,6.7C9.3,6.7,9.3,6.7,9.3,6.7c0.7,0,1.4,0,2,0
    c0.4,0,0.4,0,0.3-0.4c-0.1-0.8-0.3-1.6-0.8-2.2c-0.5-0.7-1.2-0.9-2-0.8c-0.6,0.1-1,0.5-1.3,1C7.1,5,6.9,5.8,6.9,6.6
    c0,0.1,0,0.2,0.1,0.2C7.8,6.7,8.5,6.7,9.3,6.7z M9.3,13.8C9.3,13.8,9.3,13.8,9.3,13.8c0.2,0,0.4,0,0.7,0c0.1,0,0.2,0,0.2-0.2
    c0-0.3,0-0.7,0-1c0-0.1,0-0.2,0.1-0.3c0.9-0.7,0.7-2.2-0.4-2.6c-0.7-0.3-1.5,0-1.9,0.6c-0.4,0.6-0.2,1.5,0.4,1.9
    c0.1,0.1,0.1,0.1,0.1,0.2c0,0.4,0,0.7,0,1.1c0,0.1,0,0.2,0.1,0.1C8.8,13.8,9,13.8,9.3,13.8z"
                          />
                        </svg>
                      </div>
                      <div data-gift-unlock="2" className="gift-unlockers">
                        2+ Pillow
                      </div>
                    </div>
                    <div
                      data-bundle-gift="3"
                      className="gift-checkmark is-active w-embed"
                    >
                      <svg
                        width="420"
                        height="420"
                        viewbox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                    <div className="gift-price-badge">
                      <div className="text-block-228">
                        FREE <span className="strikethroughs">$8.95</span>
                      </div>
                    </div>
                  </div>
                  <div
                    data-gift="3"
                    class={`gift_card ${quantity >= 3 ? "is-active" : ""}`}
                  >
                    <img
                      id="gift1image"
                      loading="eager"
                      alt=""
                      src="images/Small-03.svg"
                      className="gift_image"
                    />
                    <div data-bundle-gift="3" className="gift-overlay"></div>
                    <div className="gift-locker">
                      <div className="gift-lockings w-embed">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 18.6 18.6"
                          style={{ enableBackground: "new 0 0 18.6 18.6" }}
                          width="100%"
                          height="100%"
                        >
                          <path
                            fill="currentColor"
                            d="M14.6,11c0,0.8,0,1.6,0,2.4c0,0.8-0.3,1.4-0.8,2c-0.8,0.9-1.9,1.4-3.1,1.6c-1.1,0.2-2.3,0.2-3.4-0.1
      c-0.9-0.3-1.8-0.7-2.5-1.4c-0.6-0.6-1-1.4-0.9-2.3c0-1.5,0-3,0-4.4c0-0.9,0.4-1.5,1.2-1.9c0.1,0,0.1-0.1,0.1-0.2c0-1,0.2-2,0.7-2.9
      c0.5-0.9,1.1-1.6,2-2c1.2-0.6,2.4-0.4,3.5,0.5c0.8,0.6,1.2,1.4,1.6,2.3c0.3,0.7,0.4,1.4,0.4,2.2c0,0.1,0,0.1,0.1,0.2
      c0.8,0.4,1.2,1,1.2,1.8C14.6,9.5,14.6,10.3,14.6,11C14.6,11,14.6,11,14.6,11z M9.3,6.7C9.3,6.7,9.3,6.7,9.3,6.7c0.7,0,1.4,0,2,0
      c0.4,0,0.4,0,0.3-0.4c-0.1-0.8-0.3-1.6-0.8-2.2c-0.5-0.7-1.2-0.9-2-0.8c-0.6,0.1-1,0.5-1.3,1C7.1,5,6.9,5.8,6.9,6.6
      c0,0.1,0,0.2,0.1,0.2C7.8,6.7,8.5,6.7,9.3,6.7z M9.3,13.8C9.3,13.8,9.3,13.8,9.3,13.8c0.2,0,0.4,0,0.7,0c0.1,0,0.2,0,0.2-0.2
      c0-0.3,0-0.7,0-1c0-0.1,0-0.2,0.1-0.3c0.9-0.7,0.7-2.2-0.4-2.6c-0.7-0.3-1.5,0-1.9,0.6c-0.4,0.6-0.2,1.5,0.4,1.9
      c0.1,0.1,0.1,0.1,0.1,0.2c0,0.4,0,0.7,0,1.1c0,0.1,0,0.2,0.1,0.1C8.8,13.8,9,13.8,9.3,13.8z"
                          ></path>
                        </svg>
                      </div>
                      <div data-gift-unlock="3" className="gift-unlockers">
                        3+ Pillows
                      </div>
                    </div>
                    <div
                      data-bundle-gift="3"
                      className="gift-checkmark is-active w-embed"
                    >
                      <svg
                        width="420"
                        height="420"
                        viewbox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                    <div className="gift-price-badge">
                      <div className="text-block-228">
                        FREE <span className="strikethroughs">$19.99</span>
                      </div>
                    </div>
                  </div>
                  <div
                    data-gift="4"
                    class={`gift_card ${quantity >= 3 ? "is-active" : ""}`}
                  >
                    <img
                      id="gift1image"
                      loading="eager"
                      alt=""
                      src="images/Small-01.svg"
                      className="gift_image"
                    />
                    <div data-bundle-gift="4" className="gift-overlay"></div>
                    <div className="gift-locker">
                      <div className="gift-lockings w-embed">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 18.6 18.6"
                          style={{ enableBackground: "new 0 0 18.6 18.6" }}
                          width="100%"
                          height="100%"
                        >
                          <path
                            fill="currentColor"
                            d="M14.6,11c0,0.8,0,1.6,0,2.4c0,0.8-0.3,1.4-0.8,2c-0.8,0.9-1.9,1.4-3.1,1.6c-1.1,0.2-2.3,0.2-3.4-0.1
      c-0.9-0.3-1.8-0.7-2.5-1.4c-0.6-0.6-1-1.4-0.9-2.3c0-1.5,0-3,0-4.4c0-0.9,0.4-1.5,1.2-1.9c0.1,0,0.1-0.1,0.1-0.2c0-1,0.2-2,0.7-2.9
      c0.5-0.9,1.1-1.6,2-2c1.2-0.6,2.4-0.4,3.5,0.5c0.8,0.6,1.2,1.4,1.6,2.3c0.3,0.7,0.4,1.4,0.4,2.2c0,0.1,0,0.1,0.1,0.2
      c0.8,0.4,1.2,1,1.2,1.8C14.6,9.5,14.6,10.3,14.6,11C14.6,11,14.6,11,14.6,11z M9.3,6.7C9.3,6.7,9.3,6.7,9.3,6.7c0.7,0,1.4,0,2,0
      c0.4,0,0.4,0,0.3-0.4c-0.1-0.8-0.3-1.6-0.8-2.2c-0.5-0.7-1.2-0.9-2-0.8c-0.6,0.1-1,0.5-1.3,1C7.1,5,6.9,5.8,6.9,6.6
      c0,0.1,0,0.2,0.1,0.2C7.8,6.7,8.5,6.7,9.3,6.7z M9.3,13.8C9.3,13.8,9.3,13.8,9.3,13.8c0.2,0,0.4,0,0.7,0c0.1,0,0.2,0,0.2-0.2
      c0-0.3,0-0.7,0-1c0-0.1,0-0.2,0.1-0.3c0.9-0.7,0.7-2.2-0.4-2.6c-0.7-0.3-1.5,0-1.9,0.6c-0.4,0.6-0.2,1.5,0.4,1.9
      c0.1,0.1,0.1,0.1,0.1,0.2c0,0.4,0,0.7,0,1.1c0,0.1,0,0.2,0.1,0.1C8.8,13.8,9,13.8,9.3,13.8z"
                          ></path>
                        </svg>
                      </div>
                      <div data-gift-unlock="4" className="gift-unlockers">
                        3+ Pillows
                      </div>
                    </div>
                    <div
                      data-bundle-gift="3"
                      className="gift-checkmark is-active w-embed"
                    >
                      <svg
                        width="420"
                        height="420"
                        viewbox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                    <div className="gift-price-badge">
                      <div className="text-block-228">
                        FREE <span className="strikethroughs">$79.00</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-gift="titles" class="gift-titles">
                  <div data-gift="1" class="gift_title is-active">
                    <div class="gift-title_text">VIP Discount</div>
                  </div>
                  <div data-gift="2" class="gift_title is-active">
                    <div class="gift-title_text">Free Shipping</div>
                  </div>
                  <div data-gift="3" class="gift_title">
                    <div class="gift-title_text">Mystery Gift</div>
                  </div>
                  <div data-gift="4" class="gift_title">
                    <div class="gift-title_text">Free Pillow</div>
                  </div>
                </div>
              </div>
              <div className="spacer-xsmall"></div>
              <div className="cart-item_block">
                <div data-flavor="original" className="cart_line-item">
                  <div className="cart_card-wrapper">
                    <div className="pillow-image_block">
                      <div className="qty-item is-active">
                        <div
                          id="top_quantity"
                          data-quantity="display"
                          className="line_item-qty"
                        >
                          {quantity}
                        </div>
                      </div>
                      <img
                        width="280"
                        height="Auto"
                        alt=""
                        src="https://uploads-ssl.webflow.com/5f2a8f397e6634dbde061f19/66b51b47b39fc548fd2869ae_felo-latest-pillow-reduced.jpg"
                        loading="eager"
                        className="pillow_image"
                      />
                      <div className="verticle-badge">
                        <div className="text-block-227">
                          SOLD OUT 3X <span className="fire-icon"></span>
                        </div>
                      </div>
                    </div>
                    <div className="cart_card-info">
                      <div className="cart_content">
                        <div className="cart_description">
                          <div className="cart_item-name">
                            Felo Pillow - {isUpgrade ? "King" : "Queen"} Size
                          </div>
                          <div className="cart_pricing-block">
                            <div
                              id="each_price_cart_free"
                              className="sum-price reduced old-price-txt hidden_attribute is-left"
                            >
                              ${dealActualPrice}/ea
                            </div>
                            <div
                              id="each_price_cart"
                              className="sum-price reduced"
                            >
                              ${dealPrice}/ea
                            </div>
                          </div>
                        </div>
                        <div className="cart_quantity-block">
                          <div className="cart_quantity-container">
                            <div
                              role="button"
                              data-quantity="decrease"
                              className="cart_qty1-handler is-active"
                              onClick={() => onHandleChange("decrease")}
                            >
                              -
                            </div>
                            <div
                              data-quantity="display"
                              className="cart_qty1-display is-active"
                            >
                              {quantity}
                            </div>
                            <div
                              role="button"
                              data-quantity="increase"
                              className="cart_qty1-handler is-active"
                              onClick={() => onHandleChange("increase")}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stock_label in-stock">BACK IN STOCK</div>
                </div>
                {quantity === 3 && (
                  <div data-flavor="original" className="cart_line-item">
                    <div className="cart_card-wrapper">
                      <div className="pillow-image_block">
                        <div className="qty-item is-active">
                          <div
                            id="top_quantity"
                            data-quantity="display"
                            className="line_item-qty"
                          >
                            1
                          </div>
                        </div>
                        <img
                          width="280"
                          height="Auto"
                          alt=""
                          src="https://uploads-ssl.webflow.com/5f2a8f397e6634dbde061f19/66b51b47b39fc548fd2869ae_felo-latest-pillow-reduced.jpg"
                          loading="eager"
                          className="pillow_image"
                        />
                      </div>
                      <div className="cart_card-info">
                        <div className="cart_content">
                          <div className="cart_description">
                            <div className="cart_item-name">
                              Felo Pillow - {isUpgrade ? "King" : "Queen"} Size
                            </div>
                            <div className="cart_pricing-block">
                              <div
                                id="each_price_cart_free"
                                className="sum-price reduced old-price-txt hidden_attribute is-left"
                              >
                                ${dealActualPrice}/ea
                              </div>
                              <div
                                id="each_price_cart"
                                className="saving-offer-text"
                              >
                                FREE
                              </div>
                            </div>
                          </div>
                          <div id="lowStock" className="ships-by-box">
                            <div className="cart_quantity-block"></div>
                            <div className="ring-container w-embed">
                              <div className="ringring"></div>
                              <div className="circle"></div>
                            </div>
                            <div id="order-message" className="order-by-text">
                              Order by {freeGiftsDate}, for guaranteed FREE
                              Gifts
                            </div>
                          </div>
                          <div className="cart_badge-block"></div>
                        </div>
                      </div>
                    </div>
                    <div className="time_label">
                      {formatTime(time)} REMAINING
                    </div>
                  </div>
                )}
                <div
                  className="new-drawer_upgrade-block"
                  onClick={() => onUpgrade()}
                >
                  <div className="upgrade-block_heading">UPGRADE TO KING</div>
                  <div className="ug-content_block">
                    <div className="ug-block_text">
                      Upgrade to king size for just{" "}
                      <span className="text-span-169">
                        $9.99 extra per pillow
                      </span>
                    </div>
                    <a
                      href="#"
                      className={`upgrade-block_button w-inline-block ${
                        isUpgrade ? " upgrade-active" : ""
                      }`}
                    >
                      <div className="upgrade-button_text">
                        {isUpgrade ? "Upgraded" : "Upgrade"}{" "}
                        {isUpgrade && <i class="fa-solid fa-check"></i>}
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="spacer-xsmall"></div>
            </div>
            <div className="new-drawer_footer">
              <button
                class={`checkout_button-shopify w-inline-block ${
                  loading ? "pay-now-bg" : ""
                }`}
                disabled={loading}
                onClick={proceedToCheckout}
              >
                {loading ? (
                  <div className="ck_button-text">
                    <Oval
                      height="46"
                      width="46"
                      color="#ffffff"
                      secondaryColor="ffffff"
                      ariaLabel="oval-loading"
                      wrapperClass="loader-wrapper"
                    />
                  </div>
                ) : (
                  <div>
                    <div className="ck_button-text ">Proceed to Checkout</div>
                    <div className="ck-countdown-text">
                      Sale Ends In <span id="mobile_timer">{timer}</span>
                    </div>
                  </div>
                )}
              </button>
              <div className="checkout_badge-block">
                <div className="co_left-block">
                  <img
                    loading="lazy"
                    src="https://uploads-ssl.webflow.com/5f2a8f397e6634dbde061f19/66be8c11e82d92e8c12b43ef_fire.svg"
                    alt=""
                    className="gray-icon-2"
                  />
                  <div className="co_left-text">
                    Hurry! <span className="text-span-167">126 people</span> are
                    on checkout
                  </div>
                </div>
                <div className="co_right-block">
                  <img
                    loading="lazy"
                    src="https://uploads-ssl.webflow.com/5f2a8f397e6634dbde061f19/66be8c11e82d92e8c12b43d0_Green-circle.svg"
                    alt=""
                    className="circle-green"
                  />
                  <div className="co_right-text">Ships by  </div>
                  <div className="co_right-date">{shipsByDate}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      {openModal && (
        <div className="modal-overlay">
          <div>
            <div class="pop-up_flex-wrapper">
              {upgradeModalLoader && (
                <div className="upgrade-modal-overlay">
                  <Oval
                    height="60"
                    width="60"
                    color="#ffffff"
                    secondaryColor="#ffffff"
                    ariaLabel="oval-loading"
                    wrapperclassName="loader-wrapper"
                  />
                </div>
              )}
              {showUpgradeDeal === "upgradeToKing" && (
                <div class="pop-up_flex-primary">
                  <a
                    href="#"
                    class="pu-head_close-block w-inline-block"
                    onClick={() => {
                      proceedFromUpgradeModal(false, "modalClose");
                    }}
                  >
                    <div class="pu-close_icon"></div>
                  </a>
                  <div class="pop-up_body-inner">
                    <h1 class="pu-body_heading">
                      Upgrade to
                      <br />
                      King Size!
                    </h1>
                    <img
                      sizes="100vw"
                      srcset="images/01-p-500.png 500w, images/01.png 600w"
                      alt=""
                      src="images/01.png"
                      loading="lazy"
                      class="pu-body_image"
                    />
                    <h1 class="pu-body_sub-heading">Act Now and Upgrade...</h1>
                    <h1 class="pu-body_offer-text">
                      For Just $9.99 Extra Per Pillow!
                    </h1>
                    <div class="pop-up_footer">
                      <button
                        class="pu_upgrade-btn w-inline-block"
                        onClick={() => {
                          proceedFromUpgradeModal(true, "acceptUpgradeOffer");
                        }}
                        disabled={upgradeLoading || continueToCheckoutLoading}
                      >
                        <div class="pu_upgrade-btn-text">
                          {upgradeLoading ? (
                            <Oval
                              height="25"
                              width="25"
                              color="#ffffff"
                              secondaryColor="ffffff"
                              ariaLabel="oval-loading"
                              wrapperClass="loader-wrapper"
                            />
                          ) : (
                            "YES, UPGRADE!"
                          )}
                        </div>
                      </button>
                      <button
                        class="pu_continue-btn w-inline-block"
                        onClick={() => {
                          proceedFromUpgradeModal(
                            isUpgrade,
                            "rejectUpgradeOffer"
                          );
                        }}
                        disabled={upgradeLoading || continueToCheckoutLoading}
                      >
                        <div class="pu_continue-btn-text">
                          {continueToCheckoutLoading ? (
                            <Oval
                              height="25"
                              width="25"
                              color="black"
                              secondaryColor="black"
                              ariaLabel="oval-loading"
                              wrapperClass="loader-wrapper"
                            />
                          ) : (
                            "No Thanks, Proceed to Checkout"
                          )}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {showUpgradeDeal === "specialDeal" && (
                <div class="pop-up_flex-secondary">
                  <a
                    href="#"
                    class="pu-head_close-block w-inline-block"
                    onClick={() => {
                      setUpgradeModalLoader(true);
                      proceedFromUpgradeModal(false);
                    }}
                  >
                    <div class="pu-close_icon"></div>
                  </a>
                  <div class="pop-up_body-inner">
                    <h1 class="pu-body_heading">Special Offer!</h1>
                    <img
                      sizes="100vw"
                      srcset="images/02-p-500.png 500w, images/02.png 550w"
                      alt=""
                      src="images/02.png"
                      loading="lazy"
                      class="pu-body_image"
                    />
                    <h1 class="pu-body_sub-heading">Add One More Pillow...</h1>
                    <h1 class="pu-body_offer-text">
                      And Get Free Shipping on Your Order
                    </h1>
                    <div class="pop-up_footer">
                      <button
                        class="pu_upgrade-btn w-inline-block"
                        onClick={() => {
                          proceedFromUpgradeModal(true, "acceptSpecialOffer");
                        }}
                        disabled={upgradeLoading || continueToCheckoutLoading}
                      >
                        <div class="pu_upgrade-btn-text">
                          {upgradeLoading ? (
                            <Oval
                              height="25"
                              width="25"
                              color="#ffffff"
                              secondaryColor="ffffff"
                              ariaLabel="oval-loading"
                              wrapperClass="loader-wrapper"
                            />
                          ) : (
                            "YES, UPGRADE!"
                          )}
                        </div>
                      </button>
                      <button
                        class="pu_continue-btn w-inline-block"
                        onClick={() => {
                          proceedFromUpgradeModal(
                            isUpgrade,
                            "rejectSpecialOffer"
                          );
                        }}
                        disabled={upgradeLoading || continueToCheckoutLoading}
                      >
                        <div class="pu_continue-btn-text">
                          {continueToCheckoutLoading ? (
                            <Oval
                              height="25"
                              width="25"
                              color="black"
                              secondaryColor="black"
                              ariaLabel="oval-loading"
                              wrapperClass="loader-wrapper"
                            />
                          ) : (
                            "No Thanks, Proceed to Checkout"
                          )}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Drawer;
