import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="section-14">
      <section className="section_new-footer">
        <div className="container-24">
          <div className="footer-wrapper-2">
            <div className="footer-content">
              <div
                id="w-node-_5d5aa09c-0692-e678-7821-7f1dce5cb92f-c00d1fb3"
                className="footer-block"
              >
                <div className="title-small">Company</div>
                <div className="footer-name-text">Felo Products LLC</div>
                <div className="footer-name-text">
                  Address: 601 Brickell Key Drive, Suite 700, Miami, FL 33131
                </div>
                <div className="footer-name-text">help@getfelo.com</div>
                <div className="footer-name-text">1-(800)-989-1318</div>
              </div>
              <div
                id="w-node-_5d5aa09c-0692-e678-7821-7f1dce5cb93a-c00d1fb3"
                className="footer-line-div"
              ></div>
              <div
                id="w-node-_5d5aa09c-0692-e678-7821-7f1dce5cb93b-c00d1fb3"
                className="footer-block"
              >
                <div className="title-small">Quick Links</div>
                <Link to="/" className="footer-link-2">
                  Home
                </Link>
                <Link to="/about-us" className="footer-link-2">
                  About Us
                </Link>
                <Link to="/contact-us" className="footer-link-2">
                  Contact Us
                </Link>
              </div>
              <div
                id="w-node-_5d5aa09c-0692-e678-7821-7f1dce5cb945-c00d1fb3"
                className="footer-line-div"
              ></div>
              <div
                id="w-node-_5d5aa09c-0692-e678-7821-7f1dce5cb946-c00d1fb3"
                className="footer-block"
              >
                <div className="title-small">Store Policies</div>
                <Link to="/shipping-policy" className="footer-link-2">
                  Shipping Policy
                </Link>
                <Link to="/privacy-policy" className="footer-link-2">
                  Privacy Policy
                </Link>
                <Link to="/payment-policy" className="footer-link-2">
                  Payment Policy
                </Link>
                <Link to="/terms-conditions" className="footer-link-2">
                  Terms &amp; Conditions
                </Link>
                <Link to="/return-refund-policy" className="footer-link-2">
                  Return &amp; Refund Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-divider"></div>
        <div className="footer-copyright-center">
          Copyright 2024. Felo All rights reserved.
        </div>
        <div className="footer-badges-block">
          <img
            src="images/footer-badges.png"
            loading="lazy"
            sizes="(max-width: 479px) 91vw, 300px"
            srcset="
                    images/footer-badges-p-500.png   500w,
                    images/footer-badges-p-800.png   800w,
                    images/footer-badges-p-1080.png 1080w,
                    images/footer-badges-p-1600.png 1600w,
                    images/footer-badges.png        1624w
                  "
            alt=""
            className="footer-badges-png"
          />
        </div>
      </section>
    </section>
  );
};

export default Footer;
