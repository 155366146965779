const MobileSectionHeader = () => {
  return (
    <div className="mobile-hero-div is-reduced">
      <div className="mob-top-hero-div is-center">
        <div className="hero-heading_wrapper">
          <h1 className="hero-heading_text">Sleep Better, Wake Up Pain Free</h1>
        </div>
        <div className="tp-new-block is-mod is-center">
          <div className="div-block-883">
            <img
              src="images/Trustpilot_Stars.svg"
              loading="lazy"
              alt=""
              className="yp-img"
            />
          </div>
          <div>
            <div className="tt-rat-txt-2 is-up">
              Excellent 4.6 | 10,563 Reviews
            </div>
          </div>
        </div>
        <div className="gg-div on-left">
          <img
            src="images/gg1_1gg1.webp"
            loading="lazy"
            width="191.5"
            alt=""
            className="mob-gg-img on-left"
          />
        </div>
        <div className="cc-div on-left">
          <img
            src="images/04.svg"
            loading="lazy"
            alt=""
            className="mob-gold-img on-left"
          />
        </div>
        <div className="cc-div on-left">
          <img
            src="images/01.svg"
            loading="lazy"
            alt=""
            className="mob-third-img on-left"
          />
        </div>
      </div>
      <div className="as-shown-div with-space">
        <img
          src="images/mob-square_1mob-square.webp"
          loading="lazy"
          sizes="(max-width: 479px) 92vw, 100vw"
          srcset="
                      images/mob-square_1-p-500.png      500w,
                      images/mob-square_1mob-square.webp 728w
                    "
          alt=""
        />
      </div>
    </div>
  );
};

export default MobileSectionHeader;
