const LogosSlider = () => {
  return (
    <div className="logos-3">
      <div className="logo-container">
        <div className="content-holder onmainsite">
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/usa-today-seeklogo.com_1usa-today-seeklogo.com.webp"
              loading="lazy"
              alt=""
              className="logo-img-8"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/yahoo-new-2019-seeklogo.com-2_1yahoo-new-2019-seeklogo.com-2.webp"
              loading="lazy"
              alt=""
              className="logo-img-5 yh1"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/the-atlantic-magazine-seeklogo.com_1the-atlantic-magazine-seeklogo.com.webp"
              loading="lazy"
              alt=""
              className="logo-img-6 yh2"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/us-weekly-magazine_1us-weekly-magazine.webp"
              loading="lazy"
              alt=""
              className="logo-img-7 yt1"
            />
          </div>
        </div>
        <div className="content-holder onmainsite">
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/nbc002_logo_vertical_bw_1nbc002_logo_vertical_bw.webp"
              loading="lazy"
              alt=""
              className="logo-img-9"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/bustle-ar21_1bustle-ar21.webp"
              loading="lazy"
              alt=""
              className="logo-img-2"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/futurism_1futurism.webp"
              loading="lazy"
              alt=""
              className="logo-img-3"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/NBC_1NBC.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-9"
            />
          </div>
        </div>
        <div className="content-holder onmainsite">
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/usa-today-seeklogo.com_1usa-today-seeklogo.com.webp"
              loading="lazy"
              alt=""
              className="logo-img-8"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/yahoo-new-2019-seeklogo.com-2_1yahoo-new-2019-seeklogo.com-2.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-5 yh1"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/the-atlantic-magazine-seeklogo.com_1the-atlantic-magazine-seeklogo.com.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-6 yh2"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/us-weekly-magazine_1us-weekly-magazine.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-7 yt1"
            />
          </div>
        </div>
        <div className="content-holder onmainsite">
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/nbc002_logo_vertical_bw_1nbc002_logo_vertical_bw.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-9"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/bustle-ar21_1bustle-ar21.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-2"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/futurism_1futurism.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-3"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/NBC_1NBC.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-9"
            />
          </div>
        </div>
        <div className="content-holder onmainsite">
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/lat_logo_1lat_logo.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img lozad"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/hp_logo_1hp_logo.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-2 lozad"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/Allure_logo_1Allure_logo.webp"
              loading="lazy"
              alt=""
              className="logo-img-3 lozad"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/bi_logo_1bi_logo.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-4 lozad"
            />
          </div>
        </div>
      </div>
      <div className="logo-container">
        <div className="content-holder onmainsite">
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/usa-today-seeklogo.com_1usa-today-seeklogo.com.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-8"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/yahoo-new-2019-seeklogo.com-2_1yahoo-new-2019-seeklogo.com-2.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-5 yh1"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/the-atlantic-magazine-seeklogo.com_1the-atlantic-magazine-seeklogo.com.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-6 yh2"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/us-weekly-magazine_1us-weekly-magazine.webp"
              loading="lazy"
              alt=""
              className="logo-img-7 yt1"
            />
          </div>
        </div>
        <div className="content-holder onmainsite">
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/nbc002_logo_vertical_bw_1nbc002_logo_vertical_bw.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-9"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/bustle-ar21_1bustle-ar21.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-2"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/futurism_1futurism.webp"
              loading="lazy"
              alt="nuzzle, canada, pillow, sleep"
              className="logo-img-3"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/NBC_1NBC.webp"
              loading="lazy"
              alt=""
              className="logo-img-9"
            />
          </div>
        </div>
        <div className="content-holder onmainsite">
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/usa-today-seeklogo.com_1usa-today-seeklogo.com.webp"
              loading="lazy"
              alt=""
              className="logo-img-8"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/yahoo-new-2019-seeklogo.com-2_1yahoo-new-2019-seeklogo.com-2.webp"
              loading="lazy"
              alt=""
              className="logo-img-5 yh1"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/the-atlantic-magazine-seeklogo.com_1the-atlantic-magazine-seeklogo.com.webp"
              loading="lazy"
              alt=""
              className="logo-img-6 yh2"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/us-weekly-magazine_1us-weekly-magazine.webp"
              loading="lazy"
              alt=""
              className="logo-img-7 yt1"
            />
          </div>
        </div>
        <div className="content-holder onmainsite">
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/nbc002_logo_vertical_bw_1nbc002_logo_vertical_bw.webp"
              loading="lazy"
              alt=""
              className="logo-img-9"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/bustle-ar21_1bustle-ar21.webp"
              loading="lazy"
              alt=""
              className="logo-img-2"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/futurism_1futurism.webp"
              loading="lazy"
              alt=""
              className="logo-img-3"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/NBC_1NBC.webp"
              loading="lazy"
              alt=""
              className="logo-img-9"
            />
          </div>
        </div>
        <div className="content-holder onmainsite">
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/lat_logo_1lat_logo.webp"
              loading="lazy"
              alt=""
              className="logo-img lozad"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/hp_logo_1hp_logo.webp"
              loading="lazy"
              alt=""
              className="logo-img-2 lozad"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/Allure_logo_1Allure_logo.webp"
              loading="lazy"
              alt=""
              className="logo-img-3 lozad"
            />
          </div>
          <div className="logo-slide-wrapper logsiteloop">
            <img
              src="images/bi_logo_1bi_logo.webp"
              loading="lazy"
              alt=""
              className="logo-img-4 lozad"
            />
          </div>
        </div>
      </div>
      <div className="logo-center-el">
        <div className="text-block-152 jo">AS SEEN ON</div>
      </div>
    </div>
  );
};

export default LogosSlider;
