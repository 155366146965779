import Header from "./header";
import SectionHeader from "./section-header";
import LogosSlider from "./logos-slider";
import MobileSectionHeader from "./mobile-section-header";
import SectionFAQ from "./section-faq";
import Testimonials from "./testimonials";
import SectionLoop from "./section-loop";
import SectionVideoSlider from "./sectionVideoSlider";
import LooxReviews from "./loox-reviews";
import FaqTable from "./faq-table";
import Footer from "./footer";
import Drawer from "./drawer";
import mainStore from "../../store/main";
import { useEffect } from "react";
import moment from "moment";

import mixpanel from "mixpanel-browser";
mixpanel.init("6a65c67689e1f7382d034cc2077dde08");

const Main = () => {
  const { startTimer, startTodayRemaningTimer } = mainStore();

  useEffect(() => {
    mixpanel.track("PageView", {
      "Home Page": "User landed on the Home",
    });

    return () => {};
  }, []);

  useEffect(() => {
    startTimer();
  }, [startTimer]);

  useEffect(() => {
    const now = moment();
    const endOfDay = moment().endOf("day");
    const millisecondsRemaining = endOfDay.diff(now, "milliseconds");
    startTodayRemaningTimer(millisecondsRemaining);
  }, [startTodayRemaningTimer]);

  return (
    <div>
      <main className="main-wrapper">
        <div className="wrapper mobile-lp3">
          <Header />
          <SectionHeader />
          <LogosSlider />
          <MobileSectionHeader />
          <SectionFAQ />
          <Testimonials />
          <SectionLoop />
          <SectionVideoSlider />
          <LooxReviews />
          <FaqTable />
          <Footer />
        </div>
        <Drawer />
      </main>
    </div>
  );
};

export default Main;
