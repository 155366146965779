import { Helmet } from "react-helmet";
import Footer from "../main/footer";
import TopNav from "./top-nav";
import BuyNow from "./buy-now";
import Drawer from "../main/drawer";

const ShippingPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Shipping Policy</title>
        <meta
          name="description"
          content="Learn about our shipping policy, including processing times, delivery options, shipping costs, and international shipping details. Get clear, reliable information for a seamless shopping experience."
        />
      </Helmet>
      <BuyNow />
      <TopNav />
      <div class="spacer-xxlarge"></div>
      <div class="section_content">
        <h1 class="aux-top-heading">
          <span class="text-span-163">Shipping Policy</span>
        </h1>
        <div class="aux-divider"></div>
        <div class="spacer-small"></div>
        <div class="aux-field-box">
          <div class="aux-email-text">
            <strong>
              Shipping Information
              <br />
            </strong>
            We do not ship to P.O. boxes or APO/FPO addresses. Currently, we
            only ship within the United States.
          </div>
          <div class="aux-email-text">
            <br />
            <strong>
              Shipping Methods
              <br />
            </strong>
            Handling time: 1 - 2 days (Monday – Friday)
            <br />
            Transit time: 3 - 5 days (Monday – Friday)
          </div>
          <div class="aux-email-text">
            <br />
            <strong>
              Shipping Rates Are
              <br />
            </strong>
            Shipping costs will be displayed during the checkout process and
            included in your total order amount.
          </div>
          <div class="aux-email-text">
            <br />
            <strong>
              Shipping Status
              <br />
            </strong>
            In case you don&#x27;t receive the shipping confirmation email
            immediately, don&#x27;t worry! We areaware of the delivery date or
            date range provided at checkout, and rest assured, we willdeliver
            the items within that timeframe.
          </div>
          <div class="aux-email-text">
            <br />
            <strong>
              Order Changes
              <br />
            </strong>
            Please notify us immediately if there are any changes to your order.
            Please note that customand pre-ordered pieces cannot be exchanged
            for other designs.
          </div>
          <div class="aux-email-text">
            <br />
            <strong>
              Item Not Received
              <br />
            </strong>
            If you have successfully placed an order and still haven&#x27;t
            received it, even though the tracking status indicates delivery, we
            recommend contacting the courier to locate your shipment. If the
            issue persists, kindly email us at help@getfelo.com.
          </div>
          <div class="aux-email-text">
            <br />
            <strong>
              Taxes
              <br />
            </strong>
            Our customers do not need to pay for importing fees, duties or VAT
            (Valued Additional Tax). However, some limited countries (especially
            for some European countries such as Germany, Italy, UK and Canada,
            etc.) may need to pay duties or VAT according to your countries’
            levying rules. We are required by law to charge sales tax on all
            items purchased, unless in situations where it is not required by
            law.
          </div>
          <div class="aux-email-text">
            <br />
            <strong>
              Tracking Your Order
              <br />
            </strong>
            You will receive an email notifying you once your order has shipped.
            The email will include a unique parcel tracking number. The status
            of your order can be reviewed on the couriers website. It can take a
            few days following order shipment before the tracking number becomes
            active on the courier&#x27;s system. If the information is not
            available, please try again later. Please rest assured that the
            estimated shipping time is valid regardless of the tracking site
            updates
          </div>
          <div class="aux-email-text">
            <br />
            <strong>
              For Further Inquiries
              <br />
              <br />
            </strong>
            If you have any further questions, please contact our customer
            services at:
            <br />‍<br />
            Phone: 1-(800)-989-1318
            <br />
            Email: help@getfelo.com
          </div>
        </div>
      </div>
      <div class="spacer-xxlarge"></div>
      <Footer />
      <Drawer />
    </div>
  );
};

export default ShippingPolicy;
