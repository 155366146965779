import mainStore from "../../store/main";
import { Link } from "react-router-dom";

const TopNav = () => {
  const { setDrawer } = mainStore();

  return (
    <div
      data-animation="default"
      data-collapse="medium"
      data-duration="0"
      data-easing="linear"
      data-easing2="linear"
      role="banner"
      class="navbar-5 other-page w-nav"
    >
      <div class="nav_wrapper">
        <div class="nav_menu-wrapper">
          <nav role="navigation" class="nav_menu w-nav-menu">
            <Link to="/" className="nav_link w-nav-link">
              Home
            </Link>
            <Link to="/contact-us" className="nav_link w-nav-link">
              Contact
            </Link>
            <Link to="/shipping-policy" className="nav_link w-nav-link">
              Shipping &amp; Returns
            </Link>
          </nav>
          <div class="nav_burger-menu">
            <img
              loading="lazy"
              src="images/burger-menu-icon.svg"
              alt=""
              class="nav_burger-icon"
            />
          </div>
        </div>
        <Link to="/" class="logo_block w-inline-block">
          <img
            loading="lazy"
            src="images/Felo_Logo_White.svg"
            alt=""
            class="nav_logo-image"
          />
        </Link>
        <a
          data-target="drawer"
          href="#"
          class="nav_cart-block w-inline-block"
          onClick={() => setDrawer(true)}
        >
          <img
            loading="lazy"
            src="images/cart-icon.svg"
            alt=""
            class="nav_cart-image"
          />
        </a>
        <div class="menu-btn_mobile w-nav-button">
          <div class="mob-burger-icon w-icon-nav-menu"></div>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
