import { Helmet } from "react-helmet";
import Footer from "../main/footer";
import TopNav from "./top-nav";
import BuyNow from "./buy-now";
import Drawer from "../main/drawer";

const PaymentPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Payment Policy</title>
        <meta
          name="description"
          content="Discover our payment policy, covering accepted payment methods, security measures, billing details, and refunds. Ensure a smooth and secure transaction experience with our easy-to-follow guidelines."
        />
      </Helmet>
      <BuyNow />
      <TopNav />
      <div class="spacer-xxlarge"></div>
      <div class="section_content">
        <h1 class="aux-top-heading">
          <span class="text-span-163">Payment Policy</span>
        </h1>
        <div class="aux-divider"></div>
        <div class="spacer-small"></div>
        <div class="aux-field-box">
          <div class="aux-email-text">
            Felo Products LLC site has an SSL certificate, which is shown on
            your browser by a padlock in the header,often next to the URL
            address. This encrypts the data you may provide to us, such as
            payment details.Payments are certified Level 1 PCI DSS compliant.
            The Payment Card Industry Data Security Standard(PCI DSS) is a
            security standard for organizations that handle credit and debit
            card information.
          </div>
          <div class="aux-email-text">
            <br />
            <strong>
              Payments meet all 6 PCI standard categories: <br />
            </strong>
            1. Maintain a secure network <br />
            2. Protect cardholder data
            <br />
            3. Maintain a vulnerability management program <br />
            4. Implement strong access control measures <br />
            5. Regularly monitor and test networks <br />
            6. Maintain an information security policy
          </div>
          <div class="aux-email-text">
            <br />
            At https://www.getfelo.com/ store, We accept all major credit cards/
            debit card and payments made through Visa, MasterCard, American
            Express, Discover, and PayPal.
          </div>
          <div class="aux-email-text">
            <br />
            <strong>
              Shipping Status
              <br />
            </strong>
            In case you don&#x27;t receive the shipping confirmation email
            immediately, don&#x27;t worry! We areaware of the delivery date or
            date range provided at checkout, and rest assured, we willdeliver
            the items within that timeframe.
          </div>
          <div class="aux-email-text">
            <br />
            Payment must be made in full before shipment.
          </div>
          <div class="aux-email-text">
            <br />
            <strong>
              For Further Inquiries
              <br />
              <br />
            </strong>
            If you have any further questions, please contact our customer
            services at:
            <br />‍<br />
            Phone: 1-(800)-989-1318
            <br />
            Email: help@getfelo.com
          </div>
        </div>
      </div>
      <div class="spacer-xxlarge"></div>
      <Footer />
      <Drawer />
    </div>
  );
};

export default PaymentPolicy;
