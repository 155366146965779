const SectionLoop = () => {
  return (
    <div className="section_loop">
      <div className="loop_sec-heading">
        <h1 className="loop-sec_heading_text">
          Why Felo Has Worked For Thousands of Americans? 
        </h1>
      </div>
      <div className="get-con">
        <div className="rev-hero-wrapper">
          <div className="hero-split">
            <h1 className="_3-col-heading">
              1. Get Deeper Sleep From Night One
            </h1>
            <p className="margin-bottom-24px">
              Our CloudFusion™ fill uses a patented process to combine fluffy
              goose down with luxurious feathers{" "}
              <strong>
                bringing you the perfect balance of softness and support.
              </strong>
              <br />
              <br />
              It gently contours and supports your head and neck for a cradling
              sensation <strong>so you can float into a deep sleep.</strong>
            </p>
          </div>
          <div className="loop_video-block">
            <div className="loop-video-frame w-embed w-iframe">
              <div style={{ position: "relative", paddingTop: "56.25%" }}>
                <iframe
                  src="https://customer-sw7km7bonaql0e2y.cloudflarestream.com/6071456c4bced4d559e7082744adba9e/iframe?muted=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-sw7km7bonaql0e2y.cloudflarestream.com%2F6071456c4bced4d559e7082744adba9e%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false"
                  style={{
                    border: "none",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                  }}
                  allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                  allowfullscreen="true"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="get-con">
        <div className="rev-hero-wrapper-two">
          <div className="hero-split">
            <h1 className="_3-col-heading">2. Soothe Away Aches &amp; Pains</h1>
            <p className="margin-bottom-24px">
              The CloudFusion™{" "}
              <strong>perfectly molds to your neck&#x27;s natural shape</strong>{" "}
              to keep it in a neutral position with the spine.
              <br />
              <br />
              This neutral alignment will{" "}
              <strong>
                relieve neck and upper back pain, stiffness, and tension
                headaches.
              </strong>
            </p>
          </div>
          <div className="loop_video-block">
            <div className="loop-video-frame w-embed w-iframe">
              <div style={{ position: "relative", paddingTop: "56.25%" }}>
                <iframe
                  src="https://customer-sw7km7bonaql0e2y.cloudflarestream.com/f8c62a978f2578953b1faf3c4d11ebdd/iframe?muted=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-sw7km7bonaql0e2y.cloudflarestream.com%2Ff8c62a978f2578953b1faf3c4d11ebdd%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false"
                  style={{
                    border: "none",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                  }}
                  allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                  allowfullscreen="true"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="get-con">
        <div className="rev-hero-wrapper">
          <div className="hero-split">
            <h1 className="_3-col-heading">
              3. Guaranteed Comfort &amp; Support in Any Sleep position 
            </h1>
            <p className="margin-bottom-24px">
              Felo is designed to gently correct your posture through the night{" "}
              <strong>no matter what position you sleep in.</strong>
              <br />
              <br />
              Whether you sleep on your side, back, stomach, or a combination of
              positions, Felo will bring you the{" "}
              <strong>comfort and support you’ve been searching for.</strong>
            </p>
          </div>
          <div className="loop_video-block">
            <div className="loop-video-frame w-embed w-iframe">
              <div style={{ position: "relative", paddingTop: "56.25%" }}>
                <iframe
                  src="https://customer-sw7km7bonaql0e2y.cloudflarestream.com/9c7d4295934c77c9522c4b73478a91b8/iframe?muted=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-sw7km7bonaql0e2y.cloudflarestream.com%2F9c7d4295934c77c9522c4b73478a91b8%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false"
                  style={{
                    border: "none",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                  }}
                  allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                  allowfullscreen="true"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionLoop;
