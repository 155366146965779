import moment from "moment";
import mainStore from "../../store/main";

const Header = () => {
  const { setDrawer, timer } = mainStore();

  let currentDate = moment();

  // Calculate the next business day
  while (currentDate.day() === 6 || currentDate.day() === 0) {
    currentDate.add(1, "day");
  }

  let oneWeekAhead = currentDate.add(1, "day");

  // Adjust again if the new date falls on a weekend
  while (oneWeekAhead.day() === 6 || oneWeekAhead.day() === 0) {
    oneWeekAhead.add(1, "day");
  }
  let shipsByDate = oneWeekAhead.format("ddd, MMM Do");

  return (
    <>
      <div>
        <div className="sticky-nav">
          <a
            href="https://offer.getnuzzle.com/checkout/secure-v06-new"
            className="button-sm-2 sticky-btn-mb cta_btn w-button"
          >
            buy now
          </a>
          <div className="sticky-nav-div">
            <div className="sticky-nav-item-new">
              <img
                loading="lazy"
                src="images/fire.svg"
                alt=""
                className="gray-icon-2"
              />
              <div className="rem-copy">
                Hurry! <span className="greens-span">126 people</span> are on
                checkout
              </div>
            </div>
            <div className="sticky-nav-item-new">
              <img
                loading="lazy"
                src="images/Green-circle.svg"
                alt=""
                className="circle-green"
              />
              <div className="ship-by-copy">
                Ships by
                <span id="date-1" className="date-1-2 date-1 timer">
                  {shipsByDate}
                </span>
              </div>
              <a
                href="#"
                data-target="drawer"
                className="desk-sticky-btn w-button"
                onClick={() => setDrawer(true)}
              >
                Redeem 62% OFF!
              </a>
            </div>
          </div>
        </div>
        <div className="stick-nav-mobile">
          <a
            data-target="drawer"
            href="#"
            className="mobile-button_sticky w-inline-block"
            onClick={() => setDrawer(true)}
          >
            <div className="mb_sticky-text">Redeem 62% OFF!</div>
            <div className="countdown-sticky-text">
              Ends In <span id="mobile_timer">{timer}</span>
            </div>
          </a>
          <div className="sticky-menu-div">
            <div className="ss-nav-1">
              <img
                loading="lazy"
                src="images/fire.svg"
                alt=""
                className="gray-icon-2"
              />
              <div className="rem-copy">
                Hurry! <span className="text-span-147">126 people</span> are on
                checkout
              </div>
            </div>
            <div className="ss-nav-2">
              <img
                loading="lazy"
                src="images/Green-circle.svg"
                alt=""
                className="circle-green"
              />
              <div className="ship-by-copy">Ships by {shipsByDate} </div>
              <div className="date-1"></div>
              <a
                href="https://offer.getnuzzle.com/checkout/secure-v06"
                className="button-sm-3 sticky-btn cta_btn w-button"
              >
                ORDER now
              </a>
            </div>
          </div>
        </div>
        <div className="change-season-wrap">
          <div className="season-div">
            <div className="offer-div-top">
              <div className="off-txt">Express Shipping from California</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
