import moment from "moment";

const SectionFAQ = () => {
  let currentDate = moment();

  // Calculate the next business day
  while (currentDate.day() === 6 || currentDate.day() === 0) {
    currentDate.add(1, "day");
  }

  let oneWeekAhead = currentDate.add(4, "day");

  // Adjust again if the new date falls on a weekend
  while (oneWeekAhead.day() === 6 || oneWeekAhead.day() === 0) {
    oneWeekAhead.add(1, "day");
  }

  let freeGiftsDate = oneWeekAhead.format("MMM, Do");

  return (
    <div className="section_faq">
      <div className="ProductSection">
        <div className="Option">
          <input className="Toggle" id="Accordion_2" type="checkbox" />
          <label className="Title" for="Accordion_2">
            <img src="img/shipping_icon.svg" alt="Icon" />
            Shipping Details
          </label>
          <div className="Content Shipping">
            <h4>
              <b>Delivery:</b> Received by {freeGiftsDate}. Order within{" "}
              <span>3 hrs 6 mins</span>
            </h4>
            <h4>
              <b>Courier Company:</b>
              <img src="img/fedex.svg" alt="Courier" /> FedEx
            </h4>
            <p>
              All orders are shipped with express service and include a tracking
              number.
            </p>
          </div>
        </div>
        <div className="Option">
          <input className="Toggle" id="Accordion_3" type="checkbox" />
          <label className="Title" for="Accordion_3">
            <img src="img/return_icon.svg" alt="Icon" />
            Return & Refund Policy
          </label>
          <div className="Content Refund">
            <ul>
              <li>
                <img src="img/free_returns.svg" alt="Free" />
                <span>Free returns within 90 days</span>
              </li>
              <li>
                <img src="img/check.svg" alt="Check" />
                <p>
                  Any item(s) are eligible for a full refund within 90 days of
                  purchase.
                </p>
              </li>
              <li>
                <img src="img/check.svg" alt="Check" />
                <p>
                  Email us at help@getfelo.com for quality US-based support.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <img src="img/shop_verified.svg" alt="Shop" />
                <span>Shop confidently with our secure systems</span>
              </li>
              <li>
                <img src="img/check.svg" alt="Check" />
                <p>Card information is secure and uncompromised</p>
              </li>
              <li>
                <img src="img/check.svg" alt="Check" />
                <p>We keep your personal data private, safe, and secure</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFAQ;
