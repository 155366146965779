import { Helmet } from "react-helmet";
import Footer from "../main/footer";
import TopNav from "./top-nav";

const AboutUs = () => {
  return (
    <div>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="We are always here to help. Please contact us by email with any questions you have."
        />
      </Helmet>
      <TopNav />
      <div class="spacer-xxlarge"></div>
      <div class="section_content">
        <h1 class="aux-top-heading">About Us</h1>
        <div class="aux-divider"></div>
        <div class="aux-sub-heading">
          Felo<strong> </strong>is designed for anyone who is looking to sleep
          their way to a better day. Whether you sleep on your side, back,
          stomach, or a combination of all three, Felo will provide you with the
          perfect support for your head and neck.
        </div>
        <div class="aux-sub-heading">
          We use world-class materials that are odor-free and do not emit any
          chemical off-gassing. Felo is completely natural and chemical-free.
          <br />
        </div>
        <div class="aux-sub-heading">
          Felo automatically positions your body so comfortably without putting
          any pressure on your neck or head. Its like magic – you just must try
          it!
          <br />
        </div>
        <div
          id="w-node-e671db2f-3788-e023-eeca-54433f9cf759-3f9cf759"
          class="spacer-large"
        ></div>
      </div>
      <div class="spacer-xxlarge"></div>
      <Footer />
    </div>
  );
};

export default AboutUs;
