import mainStore from "../../store/main";
import { Link } from "react-router-dom";

const SectionHeader = () => {
  const { setDrawer } = mainStore();

  return (
    <>
      <div>
        <div className="section_header">
          <div
            data-animation="default"
            data-collapse="medium"
            data-duration="0"
            data-easing="linear"
            data-easing2="linear"
            role="banner"
            className="navbar-5 w-nav"
          >
            <div className="nav_wrapper">
              <div className="nav_menu-wrapper">
                <nav role="navigation" className="nav_menu w-nav-menu">
                  <Link to="/" className="nav_link w-nav-link">
                    Home
                  </Link>
                  <Link to="/contact-us" className="nav_link w-nav-link">
                    Contact
                  </Link>
                  <Link to="/shipping-policy" className="nav_link w-nav-link">
                    Shipping &amp; Returns
                  </Link>
                </nav>
                {/* <div className="nav_burger-menu">
                  <img
                    loading="lazy"
                    src="images/burger-menu-icon.svg"
                    alt=""
                    className="nav_burger-icon"
                  />
                </div> */}
              </div>
              <a href="#" className="logo_block w-inline-block">
                <img
                  loading="lazy"
                  src="images/Felo_Logo_White.svg"
                  alt=""
                  className="nav_logo-image"
                />
              </a>
              <a
                data-target="drawer"
                href="#"
                className="nav_cart-block w-inline-block"
                onClick={() => setDrawer(true)}
              >
                <img
                  loading="lazy"
                  src="images/cart-icon.svg"
                  alt=""
                  className="nav_cart-image"
                />
              </a>
              <div className="menu-btn_mobile w-nav-button">
                <div className="mob-burger-icon w-icon-nav-menu"></div>
              </div>
            </div>
          </div>
          <div className="notify_wrapper">
            <div className="notify_block-one">
              <div className="notify_offer-text">You Unlocked 60% OFF!</div>
            </div>
            <div className="notify_block-two">
              <div className="notify_offer-sub-text">
                You are 1 Unit Away from a <strong>FREE PILLOW!</strong>
              </div>
            </div>
            <div className="progress-bar_wrapper">
              <div className="pro-bar_components-inner for-notify">
                <div className="pro-bar_inner-icon-block for-desk">
                  <div className="notify_progress-circle-one is-active"></div>
                  <div className="dk-progress-bar is-active"></div>
                </div>
                <div className="pro-bar_inner-icon-block for-desk">
                  <div className="notify_progress-circle-two is-active"></div>
                  <div className="dk-progress-bar is-active"></div>
                </div>
                <div className="pro-bar_inner-icon-block for-desk">
                  <div className="notify_progress-circle-three"></div>
                  <div className="dk-progress-bar"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section_hero for-desktop">
          <div className="w-layout-blockcontainer container-medium on-hero w-container">
            <h1 className="heading-style-h1 hero-heading">
              Sleep Better, Wake <br />
              Up Pain Free
            </h1>
            <div className="tp-new-block is-mod is-center">
              <div className="div-block-883">
                <img
                  src="images/Trustpilot_Stars.svg"
                  loading="lazy"
                  alt=""
                  className="yp-img"
                />
              </div>
              <div className="div-block-884">
                <div className="tt-rat-txt-2 is-up">
                  Excellent 4.6 | 10,563 Reviews
                </div>
              </div>
            </div>
            <div className="new-badges-block">
              <img src="images/badges-long_v02.png" loading="lazy" alt="" />
            </div>
            <div className="hero_btn-wrapper">
              <a
                data-target="drawer"
                href="#"
                className="hero_cta-btn w-inline-block"
                onClick={() => setDrawer(true)}
              >
                <div className="hero_cta-btn-text">Redeem 62% OFF!</div>
              </a>
              <div className="other_info-wrapper">
                <a
                  href="shipping-policy.html"
                  target="_blank"
                  className="details_link"
                >
                  Shipping Details
                </a>
                <div className="pbb-divider"></div>
                <a
                  href="return-refund-policy.html"
                  target="_blank"
                  className="details_link"
                >
                  Return &amp; Refund Policy
                </a>
              </div>
            </div>
          </div>
        </section>
        <a
          data-target="drawer"
          href="#"
          class="pro-prog_inner-body w-inline-block"
          onClick={() => setDrawer(true)}
        >
          <div class="pro-bar_components">
            <div class="pro-bar_heading">
              <div class="pro-bar_heading-text">
                <strong>EXCLUSIVE SALE! </strong>Free Gifts With Your First
                Order
              </div>
            </div>
            <div class="gift_wrapper-header">
              <div class="new-drawer_gift-block">
                <div data-gift="container" class="gift-blocks">
                  <div data-gift="1" class="gift_card is-active">
                    <img
                      id="gift1image"
                      loading="eager"
                      alt=""
                      src="images/Small-02.svg"
                      class="gift_image"
                    />
                    <div data-bundle-gift="1" class="gift-overlay"></div>
                    <div class="gift-locker">
                      <div class="gift-lockings w-embed">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewbox="0 0 18.6 18.6"
                          style={{ enableBackground: "new 0 0 18.6 18.6" }}
                          width="100%"
                          height="100%"
                        >
                          <path
                            fill="CurrentColor"
                            d="M14.6,11c0,0.8,0,1.6,0,2.4c0,0.8-0.3,1.4-0.8,2c-0.8,0.9-1.9,1.4-3.1,1.6c-1.1,0.2-2.3,0.2-3.4-0.1
	c-0.9-0.3-1.8-0.7-2.5-1.4c-0.6-0.6-1-1.4-0.9-2.3c0-1.5,0-3,0-4.4c0-0.9,0.4-1.5,1.2-1.9c0.1,0,0.1-0.1,0.1-0.2c0-1,0.2-2,0.7-2.9
	c0.5-0.9,1.1-1.6,2-2c1.2-0.6,2.4-0.4,3.5,0.5c0.8,0.6,1.2,1.4,1.6,2.3c0.3,0.7,0.4,1.4,0.4,2.2c0,0.1,0,0.1,0.1,0.2
	c0.8,0.4,1.2,1,1.2,1.8C14.6,9.5,14.6,10.3,14.6,11C14.6,11,14.6,11,14.6,11z M9.3,6.7C9.3,6.7,9.3,6.7,9.3,6.7c0.7,0,1.4,0,2,0
	c0.4,0,0.4,0,0.3-0.4c-0.1-0.8-0.3-1.6-0.8-2.2c-0.5-0.7-1.2-0.9-2-0.8c-0.6,0.1-1,0.5-1.3,1C7.1,5,6.9,5.8,6.9,6.6
	c0,0.1,0,0.2,0.1,0.2C7.8,6.7,8.5,6.7,9.3,6.7z M9.3,13.8C9.3,13.8,9.3,13.8,9.3,13.8c0.2,0,0.4,0,0.7,0c0.1,0,0.2,0,0.2-0.2
	c0-0.3,0-0.7,0-1c0-0.1,0-0.2,0.1-0.3c0.9-0.7,0.7-2.2-0.4-2.6c-0.7-0.3-1.5,0-1.9,0.6c-0.4,0.6-0.2,1.5,0.4,1.9
	c0.1,0.1,0.1,0.1,0.1,0.2c0,0.4,0,0.7,0,1.1c0,0.1,0,0.2,0.1,0.1C8.8,13.8,9,13.8,9.3,13.8z"
                          ></path>
                        </svg>
                      </div>
                      <div data-gift-unlock="1" class="gift-unlockers">
                        1 Pillow
                      </div>
                    </div>
                    <div
                      data-bundle-gift="2"
                      class="gift-checkmark is-active w-embed"
                    >
                      <svg
                        width="420"
                        height="420"
                        viewbox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                    <div class="gift-price-badge">
                      <div class="text-block-228">62% OFF</div>
                    </div>
                  </div>
                  <div data-gift="2" class="gift_card is-active">
                    <img
                      id="gift1image"
                      loading="eager"
                      alt=""
                      src="images/Small-04.svg"
                      class="gift_image"
                    />
                    <div data-bundle-gift="2" class="gift-overlay"></div>
                    <div class="gift-locker">
                      <div class="gift-lockings w-embed">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewbox="0 0 18.6 18.6"
                          style={{ enableBackground: "new 0 0 18.6 18.6" }}
                          width="100%"
                          height="100%"
                        >
                          <path
                            fill="CurrentColor"
                            d="M14.6,11c0,0.8,0,1.6,0,2.4c0,0.8-0.3,1.4-0.8,2c-0.8,0.9-1.9,1.4-3.1,1.6c-1.1,0.2-2.3,0.2-3.4-0.1
	c-0.9-0.3-1.8-0.7-2.5-1.4c-0.6-0.6-1-1.4-0.9-2.3c0-1.5,0-3,0-4.4c0-0.9,0.4-1.5,1.2-1.9c0.1,0,0.1-0.1,0.1-0.2c0-1,0.2-2,0.7-2.9
	c0.5-0.9,1.1-1.6,2-2c1.2-0.6,2.4-0.4,3.5,0.5c0.8,0.6,1.2,1.4,1.6,2.3c0.3,0.7,0.4,1.4,0.4,2.2c0,0.1,0,0.1,0.1,0.2
	c0.8,0.4,1.2,1,1.2,1.8C14.6,9.5,14.6,10.3,14.6,11C14.6,11,14.6,11,14.6,11z M9.3,6.7C9.3,6.7,9.3,6.7,9.3,6.7c0.7,0,1.4,0,2,0
	c0.4,0,0.4,0,0.3-0.4c-0.1-0.8-0.3-1.6-0.8-2.2c-0.5-0.7-1.2-0.9-2-0.8c-0.6,0.1-1,0.5-1.3,1C7.1,5,6.9,5.8,6.9,6.6
	c0,0.1,0,0.2,0.1,0.2C7.8,6.7,8.5,6.7,9.3,6.7z M9.3,13.8C9.3,13.8,9.3,13.8,9.3,13.8c0.2,0,0.4,0,0.7,0c0.1,0,0.2,0,0.2-0.2
	c0-0.3,0-0.7,0-1c0-0.1,0-0.2,0.1-0.3c0.9-0.7,0.7-2.2-0.4-2.6c-0.7-0.3-1.5,0-1.9,0.6c-0.4,0.6-0.2,1.5,0.4,1.9
	c0.1,0.1,0.1,0.1,0.1,0.2c0,0.4,0,0.7,0,1.1c0,0.1,0,0.2,0.1,0.1C8.8,13.8,9,13.8,9.3,13.8z"
                          ></path>
                        </svg>
                      </div>
                      <div data-gift-unlock="2" class="gift-unlockers">
                        2 Pillow
                      </div>
                    </div>
                    <div
                      data-bundle-gift="3"
                      class="gift-checkmark is-active w-embed"
                    >
                      <svg
                        width="420"
                        height="420"
                        viewbox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                    <div class="gift-price-badge">
                      <div class="text-block-228">
                        FREE <span class="strikethroughs">$8.95</span>
                      </div>
                    </div>
                  </div>
                  <div data-gift="3" class="gift_card">
                    <img
                      id="gift1image"
                      loading="eager"
                      alt=""
                      src="images/Small-03.svg"
                      class="gift_image"
                    />
                    <div data-bundle-gift="3" class="gift-overlay"></div>
                    <div class="gift-locker">
                      <div class="gift-lockings w-embed">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 18.6 18.6"
                          width="200px"
                          height="200px"
                          style={{ enableBackground: "new 0 0 18.6 18.6" }}
                        >
                          <path
                            fill="CurrentColor"
                            d="M14.6,11c0,0.8,0,1.6,0,2.4c0,0.8-0.3,1.4-0.8,2c-0.8,0.9-1.9,1.4-3.1,1.6c-1.1,0.2-2.3,0.2-3.4-0.1
    c-0.9-0.3-1.8-0.7-2.5-1.4c-0.6-0.6-1-1.4-0.9-2.3c0-1.5,0-3,0-4.4c0-0.9,0.4-1.5,1.2-1.9c0.1,0,0.1-0.1,0.1-0.2c0-1,0.2-2,0.7-2.9
    c0.5-0.9,1.1-1.6,2-2c1.2-0.6,2.4-0.4,3.5,0.5c0.8,0.6,1.2,1.4,1.6,2.3c0.3,0.7,0.4,1.4,0.4,2.2c0,0.1,0,0.1,0.1,0.2
    c0.8,0.4,1.2,1,1.2,1.8C14.6,9.5,14.6,10.3,14.6,11C14.6,11,14.6,11,14.6,11z M9.3,6.7C9.3,6.7,9.3,6.7,9.3,6.7c0.7,0,1.4,0,2,0
    c0.4,0,0.4,0,0.3-0.4c-0.1-0.8-0.3-1.6-0.8-2.2c-0.5-0.7-1.2-0.9-2-0.8c-0.6,0.1-1,0.5-1.3,1C7.1,5,6.9,5.8,6.9,6.6
    c0,0.1,0,0.2,0.1,0.2C7.8,6.7,8.5,6.7,9.3,6.7z M9.3,13.8C9.3,13.8,9.3,13.8,9.3,13.8c0.2,0,0.4,0,0.7,0c0.1,0,0.2,0,0.2-0.2
    c0-0.3,0-0.7,0-1c0-0.1,0-0.2,0.1-0.3c0.9-0.7,0.7-2.2-0.4-2.6c-0.7-0.3-1.5,0-1.9,0.6c-0.4,0.6-0.2,1.5,0.4,1.9
    c0.1,0.1,0.1,0.1,0.1,0.2c0,0.4,0,0.7,0,1.1c0,0.1,0,0.2,0.1,0.1C8.8,13.8,9,13.8,9.3,13.8z"
                          ></path>
                        </svg>
                      </div>
                      <div data-gift-unlock="3" class="gift-unlockers">
                        3+ Pillows
                      </div>
                    </div>
                    <div
                      data-bundle-gift="3"
                      class="gift-checkmark is-active w-embed"
                    >
                      <svg
                        width="420"
                        height="420"
                        viewbox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                    <div class="gift-price-badge">
                      <div class="text-block-228">
                        FREE <span class="strikethroughs">$19.99</span>
                      </div>
                    </div>
                  </div>
                  <div data-gift="4" class="gift_card">
                    <img
                      sizes="(max-width: 479px) 15vw, 100vw"
                      srcset="images/02-p-500.png 500w, images/02.png 550w"
                      alt=""
                      src="images/02.png"
                      loading="eager"
                      id="gift1image"
                      class="gift_image"
                    />
                    <div data-bundle-gift="4" class="gift-overlay"></div>
                    <div class="gift-locker">
                      <div class="gift-lockings w-embed">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 18.6 18.6"
                          width="200px"
                          height="200px"
                          style={{ enableBackground: "new 0 0 18.6 18.6" }}
                        >
                          <path
                            fill="CurrentColor"
                            d="M14.6,11c0,0.8,0,1.6,0,2.4c0,0.8-0.3,1.4-0.8,2c-0.8,0.9-1.9,1.4-3.1,1.6c-1.1,0.2-2.3,0.2-3.4-0.1
    c-0.9-0.3-1.8-0.7-2.5-1.4c-0.6-0.6-1-1.4-0.9-2.3c0-1.5,0-3,0-4.4c0-0.9,0.4-1.5,1.2-1.9c0.1,0,0.1-0.1,0.1-0.2c0-1,0.2-2,0.7-2.9
    c0.5-0.9,1.1-1.6,2-2c1.2-0.6,2.4-0.4,3.5,0.5c0.8,0.6,1.2,1.4,1.6,2.3c0.3,0.7,0.4,1.4,0.4,2.2c0,0.1,0,0.1,0.1,0.2
    c0.8,0.4,1.2,1,1.2,1.8C14.6,9.5,14.6,10.3,14.6,11C14.6,11,14.6,11,14.6,11z M9.3,6.7C9.3,6.7,9.3,6.7,9.3,6.7c0.7,0,1.4,0,2,0
    c0.4,0,0.4,0,0.3-0.4c-0.1-0.8-0.3-1.6-0.8-2.2c-0.5-0.7-1.2-0.9-2-0.8c-0.6,0.1-1,0.5-1.3,1C7.1,5,6.9,5.8,6.9,6.6
    c0,0.1,0,0.2,0.1,0.2C7.8,6.7,8.5,6.7,9.3,6.7z M9.3,13.8C9.3,13.8,9.3,13.8,9.3,13.8c0.2,0,0.4,0,0.7,0c0.1,0,0.2,0,0.2-0.2
    c0-0.3,0-0.7,0-1c0-0.1,0-0.2,0.1-0.3c0.9-0.7,0.7-2.2-0.4-2.6c-0.7-0.3-1.5,0-1.9,0.6c-0.4,0.6-0.2,1.5,0.4,1.9
    c0.1,0.1,0.1,0.1,0.1,0.2c0,0.4,0,0.7,0,1.1c0,0.1,0,0.2,0.1,0.1C8.8,13.8,9,13.8,9.3,13.8z"
                          ></path>
                        </svg>
                      </div>
                      <div data-gift-unlock="4" class="gift-unlockers">
                        3+ Pillows
                      </div>
                    </div>
                    <div
                      data-bundle-gift="3"
                      class="gift-checkmark is-active w-embed"
                    >
                      <svg
                        width="420"
                        height="420"
                        viewbox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                    <div class="gift-price-badge">
                      <div class="text-block-228">
                        FREE <span class="strikethroughs">$79.00</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-gift="titles" class="gift-titles">
                  <div data-gift="1" class="gift_title is-active">
                    <div class="gift-title_text">VIP Discount</div>
                  </div>
                  <div data-gift="2" class="gift_title is-active">
                    <div class="gift-title_text">Free Shipping</div>
                  </div>
                  <div data-gift="3" class="gift_title">
                    <div class="gift-title_text">Mystery Gift</div>
                  </div>
                  <div data-gift="4" class="gift_title">
                    <div class="gift-title_text">Free Pillow</div>
                  </div>
                </div>
                <div class="w-embed"></div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default SectionHeader;
