import { Helmet } from "react-helmet";
import Footer from "../main/footer";
import TopNav from "./top-nav";
import BuyNow from "./buy-now";
import Drawer from "../main/drawer";

const ReturnRefundPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Return & Refund Policy</title>
        <meta
          name="description"
          content="Have peace of mind with our 90 day money back guarantee"
        />
      </Helmet>
      <BuyNow />
      <TopNav />
      <div class="spacer-xxlarge"></div>
      <div class="section_content">
        <h1 class="aux-top-heading">
          <span class="text-span-163">Return &amp; Refund Policy</span>
        </h1>
        <section class="_3-box-block">
          <div class="container-23">
            <div class="pricing-grid">
              <div
                id="w-node-e21284f1-2cc0-bb1d-f745-8fb8bc5ead2a-c00d1f99"
                class="pricing-card-three"
              >
                <div class="boxy-div">
                  <img
                    src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a55c612aae2_portfolio%202%20-%20wide.svg"
                    loading="lazy"
                    alt=""
                    class="pricing-image"
                  />
                  <p class="aux-email-text">Try Felo pillow for 90 nights</p>
                </div>
              </div>
              <div
                id="w-node-e21284f1-2cc0-bb1d-f745-8fb8bc5ead34-c00d1f99"
                class="pricing-card-three"
              >
                <div class="boxy-div">
                  <img
                    src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124ace5e12aae4_portfolio%204%20-%20wide.svg"
                    loading="lazy"
                    alt=""
                    class="pricing-image"
                  />
                  <p class="aux-email-text">
                    Not Happy? Return the pillow, no questions asked
                  </p>
                </div>
              </div>
              <div
                id="w-node-e21284f1-2cc0-bb1d-f745-8fb8bc5ead3e-c00d1f99"
                class="pricing-card-three"
              >
                <div class="boxy-div">
                  <img
                    src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124af1aa12aadf_placeholder%201.svg"
                    loading="lazy"
                    alt=""
                    class="pricing-image"
                  />
                  <p class="aux-email-text">Get a refund</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="aux-divider"></div>
        <div
          id="w-node-e671db2f-3788-e023-eeca-54433f9cf759-3f9cf759"
          class="spacer-large"
        ></div>
        <div class="aux-field-box">
          <div class="aux-label-text">
            Please Read Our Return &amp; Refund Policy Carefully
          </div>
          <div class="aux-email-text">
            <strong>
              Return: <br />
            </strong>
            ‍<br />
            We have a 90-day return policy, which means you have 90 days after
            receiving your item to request a return. <br />
            <br />
            To be eligible for a return, you will just need to show the receipt
            or proof of purchase. <br />
            <br />
            To start a return, you can contact us at help@getfelo.com. Once your
            return is accepted, we’ll send you a return shipping label, as well
            as instructions on how and where to send your package. Items sent
            back to us without first requesting a return will not be accepted.{" "}
            <br />
            <br />
            You can always contact us for any return questions at
            help@getfelo.com.
          </div>
          <div class="aux-email-text">
            <br />
            How can customers return your products?
            <br />• By mail
            <br />
            What is the restocking fee?
            <br />• No restocking fee
            <br />
            The product conditions that we allow for returns:
            <br />• New and slightly used
          </div>
        </div>
        <div class="spacer-small"></div>
        <div class="aux-email-text">
          <strong>
            Which Circumstances We Offer Return &amp; Refund <br />
          </strong>
          ‍<br />
          <strong>Wrong Product: </strong>
          <br />
          If you discover your order is flawed please contact us by mail: at
          help@getfelo.com with photos of the wrong product, and we will provide
          a return shipping label. Once it is tracking, we will ship a
          replacement product immediately.
          <br />
          <br />
          <strong>Exchange: </strong>
          <br />
          We only exchange products if they are defective or damaged. If you
          need to exchange your product for the same product please contact us
          by mail: help@getfelo.com. We will process the free exchange.
          <br />
          <br />
          <strong>Change Of Mind: </strong>
          <br />
          Change of mind is not acceptable. No return or refund will be
          processed. Please Note: Color, Size, and Texture.
          <br />
          <br />
          <strong>Cancel The Order: </strong>
          <br />
          We usually ship the orders immediately, Therefore, if you would wish
          to cancel your order please contact us immediately. We will send an
          involved participation by the processing department but we will not
          guarantee that you will always return the order once you receive the
          product.
          <br />
          <br />
          <strong>**</strong> All refunds will be issued after receiving the
          returned product and inspecting it is going to take up to 4 days to
          reflect in your checking account. Customer satisfaction is very
          important to us.
          <br />
          <br />
          Non-returnable items: <br />• Gift cards <br />• Downloadable software
          products <br />• Some health and personal care items
        </div>
        <div class="aux-email-text">
          <strong>
            <br />
            To complete your return, we require a receipt or proof of purchase.
            <br />
            <br />
            Refunds:
          </strong>
          <br />‍<br />
          Once your return is received and inspected, we will send you an email
          to notify you that we have received your returned item. We will also
          notify you of the approval or rejection of your refund.
          <br />
          <br />
          If you are approved, then your refund will be processed, and a credit
          will automatically be applied to your credit card or original method
          of payment, within a certain amount of days.
          <br />
          <br />
          <strong>
            Late or missing refunds:
            <br />‍
          </strong>
          <br />
          1. If you have not received a refund yet, first check your bank
          account again. <br />
          2. Then contact your credit card company, it may take some time before
          your refund is officially posted. <br />
          3. Next, contact your bank. There is often some processing time before
          a refund is posted. <br />
          4. If you have done all of this and you still have not received your
          refund yet, please contact us.
          <br />
          <br />
          <strong>Ship Returns To: </strong>
          <br />
          <br />
          601 Brickell Key Dr, Suite 700, Miami, FL 33131, United States
          <br />
          <br />
          <strong>For Further Inquiries</strong>
          <br />
          <br />
          If you have any further questions, please contact our customer
          services at:
          <br />‍<br />
          Phone: 1-(800)-989-1318
          <br />
          Email: help@getfelo.com
          <br />
          <br />
        </div>
      </div>
      <div class="spacer-xxlarge"></div>
      <Footer />
      <Drawer />
    </div>
  );
};

export default ReturnRefundPolicy;
